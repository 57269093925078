:root {
  --font-color: #e5e9e2;
  --font-hover: #a2a6a1;
  --highlight: #84A98C;
  --primary: #354F52;
  --background: #2F3E46;
  --background-secondary: #52796F;
  --primary-active: #2F3E46;
  --shadow: #222222;
  --markdown-text: #eaeaea;
  --markdown-code-background: #354F52;
  --markdown-code-border: #124559;
  --markdown-blockquote-background: #354F52;
  --markdown-blockquote-border: #EFF6E0;
}

html,
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--font-color);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1vw;
  background-color: var(--background);
  height: 100%;
  margin: 0;
}

img {
  -webkit-filter: drop-shadow(0.3em 0.3em 0.3em var(--shadow));
  filter: drop-shadow(0.3em 0.3em 0.3em var(--shadow));
}

/* Navigation Bar */

.nav-container {
  background-image: linear-gradient(180deg, var(--primary), var(--highlight));
  -moz-box-shadow: 0 0 0.5em var(--shadow);
  -webkit-box-shadow: 0 0 0.5em var(--shadow); 
  box-shadow: 0 0 0.5em var(--shadow);
  background-color: var(--primary);
  position: fixed;
  overflow-x: hidden;
  height: 100%;
  width: 20%;
  z-index: 1;
  top: 0;
  left: 0;
}

.pfp {
  text-align: center;
  padding: 10% 0% 10% 0%;
  border-radius: 50%;
}

.pfp img {
  width: 80%;
  cursor: pointer;
  border-radius: inherit;
}

#info {
  padding: 0% 2% 0% 2%;
}

#info h1, h5 {
  margin: 0%;
  margin-bottom: 0.5em;
}

#nav-list {
  list-style-type: none;
  margin: 0;
  padding: 0 0 20% 0;
  overflow: hidden;
}

#nav-item a {
  box-shadow: inset 0.25em 0 0 0 var(--primary);
  margin: 2% 10% 2% 5% !important;
  padding: 3% 3% 3% 10% !important;
  font-size: 130%;
  text-decoration: none;
  color: var(--font-color);
  display: block;
}

#nav-item a:hover {
  background-color: var(--primary-active);
}

#nav-item .active {
  background-color: var(--primary-active);
}

.nav-container #social {
  position: fixed;
  width: 20%;
  bottom: 0;
}

.nav-container #social img {
  width: 12%;
  padding: 1%;
  cursor: pointer;
  border-radius: 10%;
  margin-right: 2%;
}

/* Main Page */

.container {
  height: 100%;
  padding: 1em;
  margin-left: 20%;
}

.button {
  text-align: center;
  background-color: var(--background-secondary);
  color: var(--font-color);
  padding: 1%;
  /* Top -> Left (Clockwise)*/
  border: none;
  border-radius: 0.5vw;
  font-size: 1.5vw;
  cursor: pointer;
}

.button:hover {
  background-color: var(--primary);
}

#contact-content {
  font-size: 130%;
}

::-webkit-scrollbar {
  width: 0%;
}

/* Markdown */

#md-content #content {
  background-color: var(--background-secondary);
  margin: 1% 0 1% 0;
  padding: 1%;
}

#md-content img {
  width: 50%;
  height: auto;
}

#md-content code {
  font-family: monospace;
  background-color: var(--markdown-code-background);
  padding: 0% 0.5% 0% 0.5%;
}

#md-content pre [class*="language-"] {
  font-family: monospace;
  display: block;
  border-radius: 0.5em;
  overflow: hidden;
  overflow-x: auto;
  white-space: pre;
  -webkit-overflow-scrolling: touch;
  border: solid 0.15em var(--markdown-code-border);
  background-color: var(--markdown-code-background);
  padding: 1%;
}

#md-content blockquote {
  background: var(--markdown-blockquote-background);
  border-left: 0.3em solid var(--markdown-blockquote-border);
  font-style: italic;
  margin: 1em 0 1em 0;
  padding: 0.5em;
  line-height: 1.5em;
}

#md-content blockquote p {
  display: inline;
}

#md-content ::-webkit-scrollbar-track {
  border-radius: 1em;
}

/* Cat Pictures */

.picture-content {
  background-color: var(--background-secondary);
  overflow: auto;
  white-space: nowrap;
  padding: 0.5% 0.5% 0% 0.5%;
}

::-webkit-scrollbar-track {
  background: var(--background);
}

::-webkit-scrollbar-thumb {
  background-color: var(--background-secondary);
  border-radius: 1em;
  border: 0.2em solid var(--background);
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--background);
}

#cat {
  width: 30%;
  height: auto;
  padding: 1%;
}

.gallery {
  margin: 1% 0 1% 0;
}

/* Links */

a {
  color: var(--font-color);
}

a:hover {
  color: var(--font-hover);
  cursor: pointer;
}

/* Tables */

table {
  border-spacing: 0;
}

.list-content {
  display: grid;
  background-color: var(--background-secondary);
  margin: 0 0 1% 0;
}

.list-title {
  font-size: 150%;
  background-color: var(--background);
  padding: 1%;
  margin: 0.5%;
}

#list-items {
  margin: 0% 0.5% 0.5% 0.5%;
  padding-left: 0%;
}

#item {
  font-size: 130%;
  text-align: left;
  background-color: var(--background);
  padding: 1%;
}

#publications-list {
  display: grid;
  background-color: var(--background-secondary);
  padding: 1%;
  font-size: 130%;
}